<template>
  <div>
    <ContentHeader title="Analisis Revenue" subTitle="Analisis Pendapatan Tindakan" url="analisis-revenue" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Statistik</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row" v-if="toogleCabang">
              <div class="col-lg-4">
                <label>Pilih Cabang</label>
                <multiselect 
                  v-model="cabang_select_value"
                  deselect-label="Can't remove this value"
                  track-by="nama"
                  label="nama"
                  placeholder=""
                  :options="cabang"
                  :loading="loading_cabang"
                  :allow-empty="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
                <span>Jenis Periode</span>
                <select class="custom-select form-control" v-model="jenis_periode">
                  <option value="tanggal">Tanggal</option>
                  <option value="bulan">Bulan</option>
                  <option value="tahun">Tahun</option>
                </select>
              </div>
              <div class="col-lg-2">
                <span>Periode Awal</span>
                <input type="date" name="" v-model="periode_awal" class="form-control">
              </div>
              <div class="col-lg-2">
                <span>Periode Akhir</span>
                <input type="date" name="" v-model="periode_akhir" class="form-control">
              </div>
              <div class="col-lg-2">
                <button class="btn btn-primary mt-4" @click="getDataResult">Tampilkan</button>
              </div>
            </div>
            <!-- Chart -->
            <v-chart class="chart" :option="option" v-if="toggle_chart" />
            <!-- End Chart -->
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'

import { use } from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from "vue-echarts";

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

export default{
  components: {
    ContentHeader,
    Multiselect,
    VChart
  },
  setup(){
    const user = computed(() => store.getters['auth/user'])
    const cabang_store = computed(() => store.getters['auth/cabang'])
    const memuat_data = ref(false)
    const cek_data = ref('')
    const periode_awal = ref('')
    const periode_akhir = ref('')
    const jenis_periode = ref('')
    const toggle_chart = ref(false)

    const getDataResult = async () => {
      memuat_data.value = true
      toggle_chart.value = false
      cek_data.value = ''
      let cabang_id = ''

      if (cabang_select_value.value == '') {
        cabang_id = user.value.cabang_id
      } else {
        cabang_id = cabang_select_value.value.id
      }

      if (periode_awal.value == '' && periode_awal.value == '' && jenis_periode.value == '') {
        memuat_data.value = false
        cek_data.value = 'Pilih Periode dan Jenis Periode'
      } else {
        let { data } = await axios.get(`api/analisis_statistik/pendapatan_administrasi/${cabang_id}/${periode_awal.value}/${periode_akhir.value}/${jenis_periode.value}`)

        if (data != 'kosong') {
          if (data.legend.length <= 10) {
            option.value.legend.data = data.legend
          }
          memuat_data.value = false
          cek_data.value = ''
          option.value.xAxis.data = data.xAxis
          option.value.series = data.series
          toggle_chart.value = true
        } else {
          memuat_data.value = false
          cek_data.value = 'Tidak Ada Data Kunjungan'
        }
      }

    }

    const toogleCabang = ref(false)
    const cabang_select_value = ref('')
    const cabang = ref([])
    const loading_cabang = ref(true)
    const getCabang = async () => {
      if (cabang_store.value.jenis == 'pusat') {
        toogleCabang.value = true
        toggle_chart.value = false
        let { data } = await axios.get(`api/cabang/getWhereCabang/${cabang_store.value.id}`)
        
        if (data != 'kosong') {
          cabang.value = data
          loading_cabang.value = false
        } else {
          cabang.value = []
          loading_cabang.value = false
        }
      } else {
        getDataResult()
      }
    }


    const option = ref({
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: []
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: []
    })
  

    onMounted(() => {
      getCabang()
    })

    return {
      cabang_select_value, cabang, loading_cabang, toogleCabang, option, jenis_periode, memuat_data, cek_data, getDataResult, toggle_chart, periode_awal, periode_akhir
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
  margin-top: 40px;
}
</style>